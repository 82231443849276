export default {
  loggedInUserPolicies                     : state => state.loggedInUserPolicies,
  issueRemoveCommentPolicies               : state => state.issueRemoveCommentPolicies,
  issueAddCommentPolicies                  : state => state.issueAddCommentPolicies,
  issueUpdatePolicies                      : state => state.issueUpdatePolicies,
  issueViewPolicies                        : state => state.issueViewPolicies,
  issueAddDocumentsPolicies                : state => state.issueAddDocumentsPolicies,
  issueDocumentRemovePolicies              : state => state.issueDocumentRemovePolicies,
  reportViewPolicies                       : state => state.reportViewPolicies,
  reportUpdatePolicies                     : state => state.reportUpdatePolicies,
  reportContentClearPolicies               : state => state.reportContentClearPolicies,
  reportAddMessagesPolicies                : state => state.reportAddMessagesPolicies,
  messageUpdatePolicies                    : state => state.messageUpdatePolicies,
  messageRemovePolicies                    : state => state.messageRemovePolicies,
  messageItemAddTranslationsPolicies       : state => state.messageItemAddTranslationsPolicies,
  translationUpdatePolicies                : state => state.translationUpdatePolicies,
  issueAddFormInstancesPolicies            : state => state.issueAddFormInstancesPolicies,
  formInstanceUpdatePolicies               : state => state.formInstanceUpdatePolicies,
  formInstanceRemovePolicies               : state => state.formInstanceRemovePolicies,
  issueUpdateIssueFieldValuesPolices       : state => state.issueUpdateIssueFieldValuesPolices,
  issuePrintPolicies                       : state => state.issuePrintPolicies,
  issueLinkAddPolicies                     : state => state.issueLinkAddPolicies,
  issueLinkRemovePolicies                  : state => state.issueLinkRemovePolicies,
  issueAddTaskPolicies                     : state => state.issueAddTaskPolicies,
  issueAddFoldersPolicies                  : state => state.issueAddFoldersPolicies,
  issueDocumentUpdatePolicies              : state => state.issueDocumentUpdatePolicies,
  messageItemUpdatePolicies                : state => state.messageItemUpdatePolicies,
  folderUpdatePolicies                     : state => state.folderUpdatePolicies,
  folderRemovePolicies                     : state => state.folderRemovePolicies,
  issueViewLogsPolicies                    : state => state.issueViewLogsPolicies,
  accessViewPolicies                       : state => state.accessViewPolicies,
  allowedIssuesAndDomainsForIssueViewPolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()

    const issueViewPolicies = state.loggedInUserPolicies["Issue view"]
    if (issueViewPolicies) {
      for (const issueViewPolicy of issueViewPolicies) {
        const wheres = issueViewPolicy.wheres
        for (const where of wheres) {
          if (where.id) {
            where.id.forEach(id => ids.add(id))
          }
          if (where.domainId) {
            where.domainId.forEach(domainId => domainIds.add(domainId))
          }
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForIssueViewLogsPolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()

    const issueViewLogsPolicies = state.loggedInUserPolicies["Issue view logs"]
    if (issueViewLogsPolicies) {
      for (const issueViewLogsPolicy of issueViewLogsPolicies) {
        const wheres = issueViewLogsPolicy.wheres
        for (const where of wheres) {
          if (where.id) {
            where.id.forEach(id => ids.add(id))
          }
          if (where.domainId) {
            where.domainId.forEach(domainId => domainIds.add(domainId))
          }
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForIssueDocumentViewPolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()

    const issueDocumentViewPolicies = state.loggedInUserPolicies["IssueDocument view"]
    if (issueDocumentViewPolicies && issueDocumentViewPolicies.length) {
      for (const issueDocumentViewPolicy of issueDocumentViewPolicies) {
        const wheres = issueDocumentViewPolicy.wheres
        for (const where of wheres) {
          where.issue && where.issue.forEach(issueAccess => {
            if (issueAccess.parent) {
              issueAccess.parent.forEach(parentIssueAccess => {
                if (parentIssueAccess.id) {
                  parentIssueAccess.id.forEach(id => ids.add(id))
                } else if (parentIssueAccess.domainId) {
                  parentIssueAccess.domainId.forEach(domainId => domainIds.add(domainId))
                }
              })
            }

            if (issueAccess.id) {
              issueAccess.id.forEach(id => ids.add(id))
            } else if (issueAccess.domainId) {
              issueAccess.domainId.forEach(domainId => domainIds.add(domainId))
            }
          })
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForIssueFieldViewPolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()
    if (state.loggedInUserPolicies["IssueField view"] && state.loggedInUserPolicies["IssueFieldValue view"]) {
      for (const issueFieldValueView of state.loggedInUserPolicies["IssueFieldValue view"]) {
        const wheres = issueFieldValueView.wheres
        for (const where of wheres) {
          const accessIssues = where.issue
          for (const accessIssue of accessIssues) {
            if (accessIssue.domainId) {
              accessIssue.domainId.forEach(domainId => domainIds.add(domainId))
            }
            if (accessIssue.id) {
              accessIssue.id.forEach(id => ids.add(id))
            }
          }
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForReportViewPolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()
    if (state.loggedInUserPolicies["Report view"]) {
      for (const reportViewPolicy of state.loggedInUserPolicies["Report view"]) {
        const wheres = reportViewPolicy.wheres
        for (const where of wheres) {
          const accessIssues = where.issue
          for (const accessIssue of accessIssues) {
            if (accessIssue.domainId) {
              accessIssue.domainId.forEach(domainId => domainIds.add(domainId))
            }
            if (accessIssue.id) {
              accessIssue.id.forEach(id => ids.add(id))
            }
          }
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForMessageViewPolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()
    if (state.loggedInUserPolicies["Message view"] && state.loggedInUserPolicies["MessageItem view"]) {
      for (const messageItemView of state.loggedInUserPolicies["MessageItem view"]) {
        const wheres = messageItemView.wheres
        for (const where of wheres) {
          const accessMessages = where.message
          for (const accessMessage of accessMessages) {
            const accessReports = accessMessage.report
            for (const accessReport of accessReports) {
              const accessIssues = accessReport.issue
              for (const accessIssue of accessIssues) {
                if (accessIssue.domainId) {
                  accessIssue.domainId.forEach(domainId => domainIds.add(domainId))
                }
                if (accessIssue.id) {
                  accessIssue.id.forEach(id => ids.add(id))
                }
              }
            }
          }
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForIssueLinkViewPolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()
    if (state.loggedInUserPolicies["IssueLink view"]) {
      for (const issueLinkViewPolicy of state.loggedInUserPolicies["IssueLink view"]) {
        const wheres = issueLinkViewPolicy.wheres
        for (const where of wheres) {
          const accessFromIssues = where.fromIssue
          const accessToIssues   = where.toIssue
          if (accessFromIssues) {
            for (const accessFromIssue of accessFromIssues) {
              if (accessFromIssue.domainId) {
                accessFromIssue.domainId.forEach(domainId => domainIds.add(domainId))
              }
              if (accessFromIssue.id) {
                accessFromIssue.id.forEach(id => ids.add(id))
              }
            }
          }
          if (accessToIssues) {
            for (const accessToIssue of accessToIssues) {
              if (accessToIssue.domainId) {
                accessToIssue.domainId.forEach(domainId => domainIds.add(domainId))
              }
              if (accessToIssue.id) {
                accessToIssue.id.forEach(id => ids.add(id))
              }
            }
          }
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForFormInstanceViewPolicy: state => {
    const domainIds                    = new Set()
    const ids                          = new Set()
    const viewFormInstanceViewPolicies = state.loggedInUserPolicies["FormInstance view"]
    if (viewFormInstanceViewPolicies && viewFormInstanceViewPolicies.length) {
      for (const viewFormInstancePolicy of viewFormInstanceViewPolicies) {
        const wheres = viewFormInstancePolicy.wheres
        for (const where of wheres) {
          where.report && where.report.forEach(reportAccess => {
            reportAccess.issue && reportAccess.issue.forEach(issueAccess => {
              if (issueAccess.id) {
                issueAccess.id.forEach(id => ids.add(id))
              }
              if (issueAccess.domainId) {
                issueAccess.domainId.forEach(id => domainIds.add(id))
              }
            })
          })

          where.issue && where.issue.forEach(issueAccess => {
            if (issueAccess.id) {
              issueAccess.id.forEach(id => ids.add(id))
            }
            if (issueAccess.domainId) {
              issueAccess.domainId.forEach(id => domainIds.add(id))
            }
          })
        }
      }
    }

    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForIssueViewCommentsPolicy: state => {
    const domainIds                 = new Set()
    const ids                       = new Set()
    const issueViewCommentsPolicies = state.loggedInUserPolicies["Issue view comments"]
    if (issueViewCommentsPolicies) {
      for (const issueViewCommentsPolicy of issueViewCommentsPolicies) {
        const wheres = issueViewCommentsPolicy.wheres
        for (const where of wheres) {
          const accessIssueIds  = where.id
          const accessDomainIds = where.domainId
          const parent          = where.parent
          if (accessIssueIds) {
            for (const issueId of accessIssueIds) {
              ids.add(issueId)
            }
          }
          if (accessDomainIds) {
            for (const domainId of accessDomainIds) {
              domainIds.add(domainId)
            }
          }
          if (parent) {
            for (const eachParent of parent) {
              const accessParentIssueIds  = eachParent.id
              const accessParentDomainIds = eachParent.domainId
              if (accessParentIssueIds) {
                for (const issueId of accessParentIssueIds) {
                  ids.add(issueId)
                }
              }
              if (accessParentDomainIds) {
                for (const domainId of accessParentDomainIds) {
                  domainIds.add(domainId)
                }
              }
            }
          }
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForAccessRemovePolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()

    const accessRemovePolicies = state.loggedInUserPolicies["Access remove"]
    if (accessRemovePolicies) {
      for (const accessAddPolicy of accessRemovePolicies) {
        const wheres = accessAddPolicy.wheres
        for (const where of wheres) {
          for (const criterion of where.criteria) {
            if (criterion.id) {
              criterion.id.forEach(id => ids.add(id))
            }
            if (criterion.domainId) {
              criterion.domainId.forEach(domainId => domainIds.add(domainId))
            }
          }
        }
      }
    }

    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  allowedIssuesAndDomainsForIssueAddAccessPolicy: state => {
    const domainIds = new Set()
    const ids       = new Set()

    const accessAddPolicies = state.loggedInUserPolicies["Issue add access"]
    if (accessAddPolicies) {
      for (const accessAddPolicy of accessAddPolicies) {
        const wheres = accessAddPolicy.wheres
        for (const where of wheres) {
          if (where.id) {
            where.id.forEach(id => ids.add(id))
          }
          if (where.domainId) {
            where.domainId.forEach(domainId => domainIds.add(domainId))
          }
        }
      }
    }
    return {
      domainIds: Array.from(domainIds),
      ids      : Array.from(ids)
    }
  },
  canAddAnyAccess: state => {
    const accessAddPolicies = state.loggedInUserPolicies["Access add"]
    if (accessAddPolicies) {
      for (const accessAddPolicy of accessAddPolicies) {
        if (!accessAddPolicy.set.roleId.length && !accessAddPolicy.set.criteria.length) {
          return true
        }
      }
    }
    return false
  },
  canRemoveAnyAccess: state => {
    const accessRemovePolicies = state.loggedInUserPolicies["Access remove"]
    if (accessRemovePolicies) {
      for (const accessRemovePolicy of accessRemovePolicies) {
        for (const where of accessRemovePolicy.wheres) {
          if (!Object.keys(where).length) {
            return true
          }
        }
      }
    }
    return false
  }
}