import store from "@/plugins/vuex"
import { PAGE_TITLES, PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

export const beforeIssues = async (to, from) => {
  const isSameRoute = to.name === from.name
  if (!isSameRoute) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUES))
    store.commit("shared/setBreadcrumbs", undefined)
    store.commit("transitions/setLastCreatedIssue", null)

    const loggedInUser                        = store.getters["auth/loggedInUser"]
    const loggedInUserIssuesFiltersPreference = store.getters["users/loggedInUserIssuesFiltersPreference"]

    const loggedInUserPolicies = store.getters["accessControl/loggedInUserPolicies"]

    const progressBarPromisesArray = []
    const dependentPromisesArray   = []

    if (loggedInUserPolicies["IssueSearch add"]) {
      const loadIssueSearchPromise = store.dispatch("issueSearch/loadIssueSearchWithCriteria", {
        criteria: {
          searchText               : null,
          issuesFilters            : loggedInUserIssuesFiltersPreference ?? {},
          searchThroughAllIssueData: false
        }
      })
      progressBarPromisesArray.push(loadIssueSearchPromise)
    }

    if (loggedInUserPolicies["Issue view"]) {
      const loadIssuesPromise = store.dispatch("issues/loadIssues", {
        properties: "id"
      })
      progressBarPromisesArray.push(loadIssuesPromise)
    }

    if (loggedInUserPolicies["FieldV2 view"]) {
      const loadFieldsV2Promise = store.dispatch("fields/loadFieldsV2")
      progressBarPromisesArray.push(loadFieldsV2Promise)
    }

    if (loggedInUserPolicies["User view"]) {
      const loadUsersPromise = store.dispatch("users/loadUsers")
      progressBarPromisesArray.push(loadUsersPromise)
    }

    if (loggedInUserPolicies["User view groups"]) {
      const loadGroupsPromise = store.dispatch("users/loadGroups", loggedInUser.id)
      progressBarPromisesArray.push(loadGroupsPromise)
    }

    if (loggedInUserPolicies["Access view"]) {
      const loadAccessesPromise = store.dispatch("accesses/loadAccesses")
      progressBarPromisesArray.push(loadAccessesPromise)
    }

    if (loggedInUserPolicies["IssueField view"]) {
      const loadIssueFieldsPromise = store.dispatch("issueFields/loadIssueFields")
      progressBarPromisesArray.push(loadIssueFieldsPromise)
    }

    if (loggedInUserPolicies["IssueFieldValue view"]) {
      const loadIssueFieldValuesPromise = store.dispatch("issueFieldValues/loadIssueFieldValues")
      progressBarPromisesArray.push(loadIssueFieldValuesPromise)
    }

    if (loggedInUserPolicies["Domain view"]) {
      const loadDomainsPromise = store.dispatch("domains/loadDomains")
      progressBarPromisesArray.push(loadDomainsPromise)
    }

    if (loggedInUserPolicies["Label view"]) {
      const loadLabelsPromise = store.dispatch("labels/loadLabels")
      progressBarPromisesArray.push(loadLabelsPromise)
    }

    if (loggedInUserPolicies["OptionListItem view"]) {
      const loadOptionListItemsPromise = store.dispatch("optionListItems/loadOptionListItems")
      progressBarPromisesArray.push(loadOptionListItemsPromise)
    }

    if (loggedInUserPolicies["Channel view"]) {
      const loadChannelsPromise = store.dispatch("channels/loadChannels")
      progressBarPromisesArray.push(loadChannelsPromise)
    }

    if (loggedInUserPolicies["IssueStatus view"]) {
      const loadIssueStatusesPromise = store.dispatch("issueStatuses/loadIssueStatuses")
      progressBarPromisesArray.push(loadIssueStatusesPromise)
    }

    if (loggedInUserPolicies["IssueResolution view"]) {
      const loadIssueResolutionsPromise = store.dispatch("issueResolutions/loadIssueResolutions")
      progressBarPromisesArray.push(loadIssueResolutionsPromise)
    }

    if (loggedInUserPolicies["Analytics view"]) {
      const loadKPIsPromise = store.dispatch("kpis/loadKPIs")
      progressBarPromisesArray.push(loadKPIsPromise)
    }

    if (loggedInUserPolicies["Language view"]) {
      const loadLanguagesPromise = store.dispatch("languages/loadLanguages")
      progressBarPromisesArray.push(loadLanguagesPromise)
    }

    if (loggedInUserPolicies["FormTemplate view"]) {
      const loadFormTemplatesPromise = store.dispatch("formTemplates/loadFormTemplates").then(() => {
        const formTemplates = store.getters["formTemplates/formTemplates"]
        if (formTemplates.length) {
          return store.dispatch("formTemplateConfigurations/loadFormTemplateConfigurations", {
            formTemplateId: formTemplates.map(formTemplate => formTemplate.id).toString()
          })
        }
      })
      progressBarPromisesArray.push(loadFormTemplatesPromise)
      loadFormTemplatesPromise.then(loadFormTemplateConfigurationsPromise => {
        dependentPromisesArray.push(loadFormTemplateConfigurationsPromise)
      })
    }

    const resolvePromisesSequentially = promises => {
      let promiseChain = Promise.resolve()

      for (const promise of promises) {
        promiseChain = promiseChain.then(() => promise)
      }

      return promiseChain
    }

    resolvePromisesSequentially(progressBarPromisesArray)
      .then(() => {
        return resolvePromisesSequentially(dependentPromisesArray)
      })
      .then(() => {
        store.commit("shared/setProgressBarInitiated", false)
      })
  }
}
