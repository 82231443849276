import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"
import { ISSUE_ACCESSES } from "@/constants/bread-crumbs/issue-accesses"

/**
 * This method will be called before navigating to issue accesses page.
 * @param {*} to route to be navigated to.
 */
export const beforeIssueAccesses = async to=> {
  store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUE_ACCESSES))
  store.commit("shared/setBreadcrumbs", ISSUE_ACCESSES(to))
  store.commit("shared/setProgressBarInitiated", true)
  store.commit("shared/setProgressBarPromisesPending", true)
  const promisesArrayToBeResolved = []
  store.commit("groups/resetGroupsUsers")
  const loadGroups        = store.dispatch("groups/loadGroups")
  const loadUsers         = store.dispatch("users/loadUsers")
  const loadAccessPromise = store.dispatch("accesses/loadAccesses")
  promisesArrayToBeResolved.push(loadGroups, loadUsers, loadAccessPromise)

  let issue = store.getters["issues/issues"].find(issue => issue.id === +to.params.id)
  if (!issue) {
    await store.dispatch("issues/loadIssue", +to.params.id)
    issue = store.getters["issues/issues"].find(issue => issue.id === +to.params.id)
  }
  if (issue) {
    const loadDomainPromise = store.dispatch("domains/loadDomain", issue.domainId)
    promisesArrayToBeResolved.push(loadDomainPromise)
    await loadAccessPromise
    const accesses = store.getters["accesses/accesses"]
    const groupIds = new Set()
    for (const access of accesses) {
      if(access.groupId) {
        if(access.criteria?.domainId === issue.domainId || access.criteria?.id === issue.id) {
          groupIds.add(access.groupId)
        }
      }
    }
    if (groupIds.size) {
      for(const groupId of Array.from(groupIds)) {
        const loadUsersPromise = store.dispatch("groups/loadUsers", groupId)
        promisesArrayToBeResolved.push(loadUsersPromise)
      }
    }
    Promise.all(promisesArrayToBeResolved).then(() => {
      store.commit("shared/setProgressBarInitiated", false)
      store.commit("shared/setProgressBarPromisesPending", false)
    })
  } else {
    return "not-found"
  }
}