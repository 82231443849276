import store from "@/plugins/vuex"
import { PAGE_TITLES,  PAGE_TITLE_WITH_TRANSLATION } from "@/constants/page-titles"

/**
* This method will be called before navigating to issue fields page.
*/
export const beforeIssueFields = async (to, from) => {
  if (!["issue-fields", "issue-field"].includes(from.name)) {
    store.commit("shared/setProgressBarInitiated", true)
    store.commit("shared/setProgressBarPromisesPending", true)
    store.commit("shared/setPageTitle", PAGE_TITLE_WITH_TRANSLATION(PAGE_TITLES.ISSUE_FIELDS))
    store.commit("shared/setBreadcrumbs", undefined)

    await store.dispatch("issueFields/loadIssueFields")

    if (to.name === "issue-field") {
      const issueFields = store.getters["issueFields/issueFields"]
      const issueField  = issueFields.find(issueField => issueField.id === +to.params.id)
      if (!issueField) {
        return "not-found"
      }
    }

    const loadFieldsV2    = store.dispatch("fields/loadFieldsV2")
    const loadOptionLists = store.dispatch("optionLists/loadOptionLists")
    Promise.all([loadFieldsV2, loadOptionLists])
      .then(() => {
        store.commit("shared/setProgressBarInitiated", false)
        store.commit("shared/setProgressBarPromisesPending", false)
      })
  }
}
